import React from 'react';
import './Css/App.css';
import Hero from './Components/Hero.js';
import Member from './Components/Member.js';
import AboutUs from './Components/AboutUS.js';
import Footer from './Components/Footer.js';
import PastEvents from './Components/PastEvents.js';
import Contact from './Components/contact.js';
import Events from './Components/Events.js';
import Founder from './Components/founder.jsx';
import Video from './Components/Video.js';

function App() {
  return (
   <div>
    <Hero/>
    <AboutUs/>
    <Video/>
    <PastEvents/>
    <Events/>
    <Member/>
    <Founder/>
    <Contact/>
    <Footer/>
   </div>
  );
}

export default App;
