import React from "react";
import "../Css/founder.css";
import Swati from '../Images/Members/Swati.jpeg';
import Harsh from '../Images/Members/Harsh.jpg';
import Nikhil from '../Images/nikhil.jpg';

function founder() {
  return (
    <div>
      <div class="container">
        <div class="box">
          <span></span>
          <div class="content">
            <div>
              <img src={Harsh} alt="i" />
              <h4>Harsh Kumar Mehta BCA(2020-2023)</h4>
            </div>
            <a href="https://github.com/harsh0519">Github</a>
          </div>
        </div>
        <div class="box">
          <span></span>
          <div class="content">
            <div>
              <img src={Swati} alt="i" />
              <h4>Swati Taheem BCA(2020-2023)</h4>
            </div>
            <a href="https://www.linkedin.com/in/swati-taheem-486a99227/">Linkedin</a>
          </div>
        </div>
        <div class="box">
          <span></span>
          <div class="content">
            <div>
              <img src={Nikhil} alt="i" />
              <h4>Nikhil Goyal(2020-2023)</h4>
            </div>
            <a href="/">Linkedin</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default founder;
