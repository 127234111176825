import React from "react";
import "../Css/Member.css";
import Bhavi from "../Images/Members/Bhavi.jpg";
import Shree from "../Components/member-img/shree.jpg";
import Smriti from "../Images/Members/Smriti.jpg";
import Sukhdev from "../Components/member-img/sukhdev.jpg";
import Ankita from "../Images/Members/Ankita.jpg";
import Muskan from "../Images/Members/MuskanMalhotra.jpg";
import MehakTaneja from "../Images/Members/Mehaktaneja.jpg";
import Khushi from "../Images/Members/KushiShig.jpg";
import Sejal from "../Components/member-img/sejal.jpg";
import Raunak from "../Images/Members/Raunak.jpg";
import Deepika from "../Images/Members/DP.jpg";
import Ashu from "../Images/Members/AshutoshSharma.jpg";
import Garima from "../Images/Members/GarimaSharma.jpg";
import Manpreet from "../Images/Members/ManpreetSingh.jpg";
import keshavSinha from "../Images/Members/keshavSinha.png";
import Tanisha from "../Images/Members/tanisha.jpg";
import Shubham from "../Images/Members/Shubham.jpg";
import SachinSoni from "../Images/Members/SachinSoni.jpg";
import DiveyChoudhary from "../Images/Members/DiveyChoudhary.jpeg";
import jaskeet from "../Images/Members/jaskeet.jpg";
import jasslean from "../Images/Members/jasslean.jpg";
import IshanKandari from "../Images/Members/IshanKandari.jpg";
import Aryan from "../Images/Members/Aryan.jpg";
import devanshu from "../Images/Members/devanshu.jpg";
import LakshayMahanjan from "../Images/Members/LakshayMahanjan.jpg";
import HardikVerma from "../Images/Members/HardikVerma.jpg";
import RiyaVerma from "../Images/Members/RiyaVerma.jpg";
import NF from "../Images/Members/notfound.png";
import Rohit from "../Images/Members/rohit.jpg";
import Part1 from "../Images/Members/part1.jpg";

export default function Member() {
  return (
    <div>
      <section id="team" className="team  section-padding">
        {/* <div class="container py-5 px-lg-5">
                <div class=" text-dark section-title position-relative text-center mb-5 pb-2 wow fadeInUp`">
                    <h1>FACULTY COORDINATOR</h1>
                </div>
                <div class="event-container d-flex justify-content-between">
                    <div class=" bg-transparent text-center border rounded text-black p-4">
                        <p> <span><span class="text-white">.</span>Advisory Board</span> <br/><span
                                > Prof.(Dr.) Rachita Rana <br/>Director
                            </span>
                            <br/><span >Mr. Shiva Sharma
                                <br/>Executive Director
                            </span>
                        </p>
                    </div>
                    <div class=" bg-transparent text-center border rounded text-black p-4">
                        <p> <span >Supervisor </span><br/><span
                                > Prof. (Dr.) Sudhir Kumar Sharma <br/>Head of
                                the
                                Department & Dean Research</span>
                        </p>
                    </div>
                    <div class=" bg-transparent text-center border rounded text-black p-4">
                        <p> <span >Working Committee</span>
                            <br/><span ><br/>
                            </span> <br/><span >Dr. Rohit Kumar Yadav<br/>Member
                            </span>
                        </p>
                    </div>
                </div>
            </div> */}
        <header className="section-header">
          <h4>Who we are</h4>
          <h2>Our Professional Team</h2>
          <p></p>
        </header>
        <div className="flex-items">
          <div>
            <img src={Khushi} alt="i" class="main-img" />
            <h4>Khushi Sihag</h4>
            <h5>President</h5>
          </div>
          <div>
            <img src={Bhavi} alt="i" class="main-img" />
            <h4>Bhavishya Verma</h4>
            <h5>Teachnical Head</h5>
          </div>
          <div>
            <img src={Shree} alt="i" class="main-img" />
            <h4>Shree Stuti</h4>
            <h5>Editorial Head</h5>
          </div>
          <div>
            <img src={Smriti} alt="i" class="main-img" />
            <h4>Smriti Dhingra</h4>
            <h5>Promotion & Marketing Head</h5>
          </div>
          <div>
            <img src={Sukhdev} alt="i" class="main-img" />
            <h4>Sukhdev Kumar Mehta </h4>
            <h5> Management Head</h5>
          </div>
        </div>
      </section>
      {/* Member of Artificial Intelligence Experience Centre */}
      <section id="team" className="team section-padding">
        <header className="section-header">
          {/* <h4>Who we are</h4> */}
          <h2>Members of Artificial Intelligence Experiential Centre</h2>
          <p></p>
        </header>
        <div className="flex-items">
          {/*
                <div>
                    <img src={Gautam} alt="i"/>
                    <h4>Gautam Kohli</h4>
                </div> */}
          <div>
            <img src={Garima} alt="i" class="main-img-demo" />
            <h4>Garima Sharma</h4>
          </div>
          <div>
            <img src={Ankita} alt="i" class="main-img-demo" />
            <h4>Ankita Gulati</h4>
          </div>
          <div>
            <img src={Shubham} alt="i" class="main-img-demo" />
            <h4>Shubham</h4>
          </div>
          <div>
            <img src={LakshayMahanjan} alt="i" class="main-img-demo" />
            <h4>Lakshay Mahanjan</h4>
          </div>
          <div>
            <img src={IshanKandari} alt="i" class="main-img-demo" />
            <h4>Ishan Kandari</h4>
          </div>
        </div>
      </section>
      {/* members second line starts here */}
      <section id="team" className="team section-padding">
        <div className="flex-items">
          <div>
            <img src={Ashu} alt="i" class="main-img-demo" />
            <h4>Ashutosh Sharma</h4>
          </div>
          <div>
            <img src={MehakTaneja} alt="i" class="main-img-demo" />
            <h4>Mehak Taneja</h4>
          </div>
          <div>
            <img src={HardikVerma} alt="i" class="main-img-demo" />
            <h4>Hardik Verma</h4>
          </div>

          <div>
            <img src={jaskeet} alt="" class="main-img-demo" />
            <h4>Jaskreet </h4>
          </div>
          <div>
            <img src={RiyaVerma} alt="i" class="main-img-demo" />
            <h4>Riya Verma</h4>
          </div>
          <div>
            <img src={Part1} alt="i" class="main-img-demo" />
            <h4>Nishtha Parashar</h4>
          </div>
        </div>
      </section>
      <section id="team" className="team section-padding">
        <div className="flex-items">
          <div>
            <img src={Raunak} alt="" class="main-img-demo" />
            <h4>Raunak Bhatia</h4>
          </div>
          <div>
            <img src={Aryan} alt="" class="main-img-demo" />
            <h4>Aryan Gambhir </h4>
          </div>
          <div>
            <img src={Deepika} alt="i" class="main-img-demo" />
            <h4>Deepika Vats</h4>
          </div>
          <div>
            <img src={devanshu} alt="" class="main-img-demo" />
            <h4>Devanshu </h4>
          </div>
          <div>
            <img src={Sejal} alt="i" class="main-img-demo" />
            <h4>Sejal</h4>
          </div>
        </div>
      </section>
      {/* Member of Robotics and Drone Club */}
      <section id="team" className="team section-padding">
        <header className="section-header">
          {/* <h4>Who we are</h4> */}
          <h2>Members of Robotics and Drone Club</h2>
          <p></p>
        </header>
        <div className="flex-items">
          <div>
            <img src={Muskan} alt="i" class="main-img-demo" />
            <h4>Muskan Malhotra</h4>
          </div>
          <div>
            <img src={Manpreet} alt="i" class="main-img-demo" />
            <h4>Manpreet singh</h4>
          </div>
          <div>
            <img src={Raunak} alt="i" class="main-img-demo" />
            <h4>Raunak Bhatia</h4>
          </div>
          <div>
            <img src={Tanisha} alt="i" class="main-img-demo" />
            <h4>Tanisha Jagg</h4>
          </div>
          <div>
            <img src={DiveyChoudhary} alt="i" class="main-img-demo" />
            <h4>Divey Choudhary</h4>
          </div>
        </div>
      </section>
      {/* Member of Programming Club */}
      <section id="team" className="team section-padding">
        <header className="section-header">
          {/* <h4>Who we are</h4> */}
          <h2>Members of Programming Club</h2>
          <p></p>
        </header>
        <div className="flex-items">
          <div>
            <img src={Bhavi} alt="i" class="main-img-demo" />
            <h4>Bhavishya Verma</h4>
          </div>
          <div>
            <img src={keshavSinha} alt="i" class="main-img-demo" />
            <h4>Keshav Sinha</h4>
          </div>
          <div>
            <img src={jasslean} alt="i" class="main-img-demo" />
            <h4>Jasleen kaur</h4>
          </div>
          <div>
            <img src={SachinSoni} alt="i" class="main-img-demo" />
            <h4>Sachin Soni</h4>
          </div>
          <div>
            <img src={Rohit} alt="i" class="main-img-demo" />
            <h4>Rohit Kumar</h4>
          </div>
        </div>
      </section>
      {/* members second line ends here */}
      <section id="team" className="team  section-padding">
        <header className="section-header">
          <h2>Our Founder</h2>
          <p></p>
        </header>
      </section>
    </div>
  );
}
